import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import { sendEmailForResetPassword } from '../../../reducers/signin';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsValidEmail } from '../../../helpers/helpers';
const sendEmailForResetPasswordAction = async (dispatch: any, data: any) => {
	return Promise.resolve(dispatch(sendEmailForResetPassword(data)).unwrap());
};
const ForgotPassword = () => {
	const dispatch = useDispatch();
	const [message, setMessage] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const status = useSelector((state: any) => state.Signin.sendEmailForResetPassword.status);
	const error = useSelector((state: any) => state.Signin.sendEmailForResetPassword.error);
	const handleEmailChange = (event: any) => {
		setEmail(event.target.value);
	};
	const sendEmail = () => {
		if (!email) {
			setEmailError('Ce champ est obligatoire');
			return;
		} else if (!checkIsValidEmail(email)) {
			setEmailError("L'email n'est pas valide");
			return;
		} else {
			setEmailError('');
		}
		let userData = {
			email: email,
		};
		sendEmailForResetPasswordAction(dispatch, userData);
	};

	useEffect(() => {
		if (status === 'success') {
			setMessage('Un email a été envoyé à votre adresse email');
		} else if (status === 'failed' && error.message === 'user not found') {
			setMessage("Ce compte n'existe pas");
		} else if (status === 'failed') {
			setMessage('Une erreur est survenue');
		}
	}, [status, error]);

	return (
		<PageWrapper
			isProtected={false}
			title={'Forgot password'}
			className={classNames({ 'bg-info': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center h1 fw-bold mt-5 mb-4'>
									<h4>
										Pour changer votre mot de passe, veuillez entrer votre
										adresse email
									</h4>
								</div>
								<form className='row g-4'>
									<div className='col-12'>
										<div className='input-group mb-3'>
											<input
												className='form-control'
												placeholder='Adresse Email'
												type='email'
												value={email}
												onChange={handleEmailChange}
											/>
										</div>
										<p style={{ color: 'red' }}>{emailError}</p>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											className='w-100 py-3'
											onClick={sendEmail}>
											Envoyer
										</Button>
									</div>
									<p className='text-center mt-3 mb-3'>{message}</p>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ForgotPassword;
