import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EntrepriseService from '../services/EntrepriseService';

export const getAllEntreprises = createAsyncThunk(
	'entreprise/get-all-entreprises',
	async (filterData: any, thunkAPI: any) => {
		try {
			const { status, data } = await EntrepriseService.getAllEntreprises(filterData);
			console.log('data', data);
			if (status === 201 || status === 200) {
				return data.value;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);
export const getEntrepriseById = createAsyncThunk(
	'entreprise/get-by-id',
	async (id: string, thunkAPI: any) => {
		try {
			const { status, data } = await EntrepriseService.getEntrepriseById(id);
			if (status === 201 || status === 200) {
				return data.value;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

export const updateEntrepriseStatus = createAsyncThunk(
	'entreprise/update-status',
	async (dataEntreprise: any, thunkAPI: any) => {
		try {
			const { status, data } = await EntrepriseService.updateEntrepriseStatus(dataEntreprise);
			if (status === 201 || status === 200) {
				return data.value;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

const initialState = {
	entreprises: {
		status: 'idle',
		data: null,
		error: {},
	},
	entreprise: {
		status: 'idle',
		data: null,
		error: {},
	},
	updateStatus: {
		status: 'idle',
		data: null,
		error: {},
	},
};
const EntrepriseSlice = createSlice({
	name: 'entreprise',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[getAllEntreprises.pending.type]: (state) => {
			state.entreprises = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getAllEntreprises.fulfilled.type]: (state, action) => {
			state.entreprises = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getAllEntreprises.rejected.type]: (state, action) => {
			state.entreprises = {
				status: 'failed',
				data: action.payload,
				error: '',
			};
		},
		[getEntrepriseById.pending.type]: (state) => {
			state.entreprise = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getEntrepriseById.fulfilled.type]: (state, action) => {
			state.entreprise = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getEntrepriseById.rejected.type]: (state, action) => {
			state.entreprise = {
				status: 'failed',
				data: action.payload,
				error: '',
			};
		},
		[updateEntrepriseStatus.pending.type]: (state) => {
			state.updateStatus = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[updateEntrepriseStatus.fulfilled.type]: (state, action) => {
			state.updateStatus = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[updateEntrepriseStatus.rejected.type]: (state, action) => {
			state.updateStatus = {
				status: 'failed',
				data: action.payload,
				error: '',
			};
		},
	},
});

export default EntrepriseSlice.reducer;
