import Api from '../config/axios';
import config from '../config/config';

export const getDonationsByDate = async (data: any) => {
	const url = config.apiUrl + '/project/NbDonationbyDate/';
	try {
		return await Api.post(url, data);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export default { getDonationsByDate };
