import Api from '../config/axios';
import config from '../config/config';

export const getAllProjects = async (filterData: any) => {
	const url = config.apiUrl + '/project/';
	console.log(filterData);
	try {
		return await Api.get(url, {
			params: filterData,
		});
	} catch (error) {
		throw new Error('An error occured');
	}
};

export const getNbProjectsAndDonationsByCategory = async (regions: any) => {
	const url = config.apiUrl + '/project/Dash/NbProjDonationByRegionCateg';
	try {
		return await Api.post(url, regions);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export const getNbProjectsByDate = async (data: any) => {
	const url = config.apiUrl + '/project/NbProjbyDate';
	try {
		return await Api.post(url, data);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export const getProjectDetails = async (id: any) => {
	const url = config.apiUrl + '/project/'+id;
	try {
		return await Api.get(url);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export const validateProject = async (data: any) => {
	const url = config.apiUrl + '/project/updateProjectStatus/'+data.id+'/'+data.status;
	try {
		return await Api.get(url);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export default {
	getAllProjects,
	getNbProjectsAndDonationsByCategory,
	getNbProjectsByDate,
	getProjectDetails,
	validateProject
};
