import Api from '../config/axios';
import config from '../config/config';

export const GetAll10Donations = async (RegionData: {}) => {
	const url = config.apiUrl + "/donation/Get/10Donation/";
  
	try {
	  return await Api.post(url,   RegionData  );
	} catch (error) {
	  throw new Error("An error occured");
	}
  };
  export const createPDF = async (RegionData: {}) => {
	const url = config.apiUrl + "/donation/createPDF/Status";
  
	try {
	  return await Api.post(url,   RegionData  );
	} catch (error) {
	  throw new Error("An error occured");
	}
  };
export default { GetAll10Donations,createPDF };
