import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AllCampaignService from "../services/AllCampaignService";


export const PDFparStatusCampaign = createAsyncThunk(
  "user/PDFparstatus",
  async (StatusData: any, thunkAPI) => {
    try {
      const { status, data } = await AllCampaignService.PDFparStatusCampaign(StatusData);
      if (status === 200) {
        console.log(data,'hahaha')
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
    CampaignRegion: {
    status: "idle",
    data:null,
    error: {},
  },
  CampaignPdf: {
    status: "idle",
    data:null,
    error: {},
  },
};
const SignInSlice = createSlice({
  name: "Pdf-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
   
    [PDFparStatusCampaign.pending.type]: (state) => {
      state.CampaignPdf = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [PDFparStatusCampaign.fulfilled.type]: (state, action) => {
      state.CampaignPdf = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [PDFparStatusCampaign.rejected.type]: (state, action) => {
      state.CampaignPdf = {
        status: "failed",
        data: action.payload,
				error: {},

      };
    },
  },
});

export default SignInSlice.reducer;
