import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AllDonationService from "../services/AllDonationService";

export const allDonation = createAsyncThunk(
  "user/allDonation",
  async (region: any, thunkAPI) => {
    try {
      const { status, data } = await AllDonationService.GetAll10Donations(region);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const createPDF = createAsyncThunk(
  "user/createPDF",
  async (region: any, thunkAPI) => {
    try {
      const { status, data } = await AllDonationService.createPDF(region);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
    DonationRegion: {
    status: "idle",
    data:null,
    error: {},
  },
  PDFdonation: {
    status: "idle",
    data:null,
    error: {},
  },
};
const SignInSlice = createSlice({
  name: "signin-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [allDonation.pending.type]: (state) => {
      state.DonationRegion = {
        status: "loading",
        data: null,

        error: {},
      };
    },
    [allDonation.fulfilled.type]: (state, action) => {
      state.DonationRegion = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [allDonation.rejected.type]: (state, action) => {
      state.DonationRegion = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
     [createPDF.pending.type]: (state) => {
      state.PDFdonation = {
        status: "loading",
        data: null,

        error: {},
      };
    },
    [createPDF.fulfilled.type]: (state, action) => {
      state.PDFdonation = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [createPDF.rejected.type]: (state, action) => {
      state.PDFdonation = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
  },
});

export default SignInSlice.reducer;
