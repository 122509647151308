import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AllProjectService from "../services/AllProjectService";

export const allProject = createAsyncThunk(
  "user/allProject",
  async (region: any, thunkAPI) => {
    try {
      const { status, data } = await AllProjectService.GetAll10Projects(region);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const filtrerparstatus = createAsyncThunk(
  "user/filtrerparstatus",
  async (StatusData: any, thunkAPI) => {
    try {
      const { status, data } = await AllProjectService.FiltrerparStatus(StatusData);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const PDFparstatus = createAsyncThunk(
  "user/PDFparstatus",
  async (StatusData: any, thunkAPI) => {
    try {
      const { status, data } = await AllProjectService.PDFparStatus(StatusData);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const PDFGetFile = createAsyncThunk(
  "user/PDFGetFile",
  async (File: any, thunkAPI) => {
    try {
      const { status, data } = await AllProjectService.GetFile(File);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
    ProjectRegion: {
    status: "idle",
    data:null,
    error: {},
  },
  statusFiltrage: {
    status: "idle",
    data:null,
    error: {},
  },
  PDFFiltrage: {
    status: "idle",
    data:null,
    error: {},
  },
  PDFfile: {
    status: "idle",
    data:null,
    error: {},
  },
};
const SignInSlice = createSlice({
  name: "signin-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [allProject.pending.type]: (state) => {
      state.ProjectRegion = {
        status: "loading",
        data: null,

        error: {},
      };
    },
    [allProject.fulfilled.type]: (state, action) => {
      state.ProjectRegion = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [allProject.rejected.type]: (state, action) => {
      state.ProjectRegion = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
    [filtrerparstatus.pending.type]: (state) => {
      state.statusFiltrage = {
        status: "loading",
        data: null,

        error: {},
      };
    },
    [filtrerparstatus.fulfilled.type]: (state, action) => {
      state.statusFiltrage = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [filtrerparstatus.rejected.type]: (state, action) => {
      state.statusFiltrage = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
    [PDFparstatus.pending.type]: (state) => {
      state.PDFFiltrage = {
        status: "loading",
        data: null,

        error: {},
      };
    },
    [PDFparstatus.fulfilled.type]: (state, action) => {
      state.PDFFiltrage = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [PDFparstatus.rejected.type]: (state, action) => {
      state.PDFFiltrage = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
    [PDFGetFile.pending.type]: (state) => {
      state.PDFfile = {
        status: "loading",
        data: null,

        error: {},
      };
    },
    [PDFGetFile.fulfilled.type]: (state, action) => {
      state.PDFfile = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [PDFGetFile.rejected.type]: (state, action) => {
      state.PDFfile = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
  },
});

export default SignInSlice.reducer;
