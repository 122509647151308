import Api from '../config/axios';
import config from '../config/config';

export const GetAllAdmin = async () => {
	const url = config.apiUrl + "/agent/";
  
	try {
	  return await Api.get(url);
	} catch (error) {
	  throw new Error("An error occured");
	}
  };
  export const updateAdminStatus = async (data: {}) => {
    const url = config.apiUrl + "/agent/updateAdminStatus/";
    try {
      return await Api.put(url, data);
    } catch (error) {
      throw new Error("An error occured");
    }
  };
  export const addAdmin = async (userData: {}) => {
    const url = config.apiUrl + "/agent/";
  
    try {
      return await Api.post(url,   userData  );
    } catch (error) {
      throw new Error("An error occured");
    }
  };
export default { GetAllAdmin ,updateAdminStatus,addAdmin};
