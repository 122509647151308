import Api from '../config/axios';
import config from '../config/config';

export const getAllCampaignsQuadratic = async (status:any) => {
	const url = config.apiUrl + "/campaign-quadratic/" ;
  
	try {
	  return await Api.post(url,status);
	} catch (error) {
	  throw new Error("An error occured");
	}
  };
  export const getDetailsCampaignsQuadratic = async (id:any) => {
	const url = config.apiUrl + "/campaign-quadratic/details/"+id ;
  
	try {
	  return await Api.get(url);
	} catch (error) {
	  throw new Error("An error occured");
	}
  };
  export const StatusCampaignsQuadratic = async (data:any) => {
	const url = config.apiUrl + "/campaign-quadratic/update-status/" ;
  
	try {
	  return await Api.put(url,data);
	} catch (error) {
	  throw new Error("An error occured");
	}
  };
export default { getAllCampaignsQuadratic,getDetailsCampaignsQuadratic,StatusCampaignsQuadratic};
