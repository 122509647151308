import Api from '../config/axios';
import config from '../config/config';

export const getCampaignDetails = async (id: any) => {
	const url = config.apiUrl + '/campaign/FindOneCampaign/' + id;
	try {
		return await Api.get(url);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export const updateCampaignStatus = async (data: any) => {
	const url = config.apiUrl + '/campaign/updateStatus';
	try {
		return await Api.post(url, data);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export default { getCampaignDetails, updateCampaignStatus };
