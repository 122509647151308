import Api from '../config/axios';
import config from '../config/config';

export const GetAll10Campaigns = async (RegionData: {}) => {
	const url = config.apiUrl + "/campaign/Get/10Campaign/";
  
	try {
	  return await Api.post(url,   RegionData  );
	} catch (error) {
	  throw new Error("An error occured");
	}
  };
  export const PDFparStatusCampaign = async (Data: {}) => {
	const url = config.apiUrl + "/campaign/createPDF/Status";
  
	try {
	  return await Api.post(url,   Data  );
	} catch (error) {
		console.log("error : ", error)
	  throw new Error("An error occured");
	}
  };
export default { GetAll10Campaigns ,PDFparStatusCampaign};
