import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import { resetPassword } from '../../../reducers/signin';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

const resetPasswordAction = async (dispatch: any, data: any) => {
	return Promise.resolve(await dispatch(resetPassword(data)).unwrap());
};
const ResetPassword = () => {
	const { token } = useParams();
	const dispatch = useDispatch();
	const status = useSelector((state: any) => state.Signin.resetPassword.status);
	const error = useSelector((state: any) => state.Signin.resetPassword.error);
	const [message, setMessage] = useState('');
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [rePasswordError, setRePasswordError] = useState('');
	const [hidePassword, setHidePassword] = useState(true);
	const [hideRePassword, setHideRePassword] = useState(true);

	const handlePasswordChange = (event: any) => {
		setPassword(event.target.value);
	};
	const handleRePasswordChange = (event: any) => {
		setRePassword(event.target.value);
	};

	const resetPasswordHandler = () => {
		if (!password) {
			setPasswordError('Ce champ est obligatoire');
			return;
		} else {
			setPasswordError('');
		}

		if (password !== rePassword) {
			setRePasswordError('Votre mot de passe ne correspond pas');
			return;
		} else {
			setRePasswordError('');
		}
		if (token) {
			const result: any = jwt_decode(token);
			let userData = {
				id: result.id,
				newpassword: password,
				confirmnewpassword: rePassword,
			};
			resetPasswordAction(dispatch, userData);
		}
	};

	useEffect(() => {
		if (status === 'success') {
			setMessage('Modification de mot de passe a été effectué avec succès');
		} else if (
			status === 'failed' &&
			error === 'password is the same thanks to put a new password'
		) {
			setMessage('Vous avez utilisé le même mot de passe');
		} else if (status === 'failed') {
			setMessage('Une erreur est survenue');
		}
	}, [status, error]);

	return (
		<PageWrapper
			isProtected={false}
			title={'Forgot password'}
			className={classNames({ 'bg-info': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center h1 fw-bold mt-5 mb-4'>
									<h4>Veuillez entrer un nouveau mot de passe</h4>
								</div>
								<form className='row g-4'>
									<div className='col-12'>
										<div className='input-group mb-3'>
											<input
												value={password}
												type={hidePassword ? 'password' : 'text'}
												autoComplete='password'
												onChange={handlePasswordChange}
												className='form-control'
												placeholder='Mot de Passe'
											/>
											<span
												className='input-group-text'
												id='basic-addon2'
												onClick={() =>
													setHidePassword((current) => !current)
												}>
												<Icon
													icon={hidePassword ? 'EyeSlash' : 'Eye'}
													size='md'
													color='primary'
												/>
											</span>
										</div>
										<p style={{ color: 'red' }}>{passwordError}</p>
									</div>
									<div className='col-12'>
										<div className='input-group mb-3'>
											<input
												value={rePassword}
												type={hideRePassword ? 'password' : 'text'}
												autoComplete='password'
												onChange={handleRePasswordChange}
												className='form-control'
												placeholder='Confirmer mot de Passe'
											/>
											<span
												className='input-group-text'
												id='basic-addon2'
												onClick={() =>
													setHideRePassword((current) => !current)
												}>
												<Icon
													icon={hideRePassword ? 'EyeSlash' : 'Eye'}
													size='md'
													color='primary'
												/>
											</span>
										</div>
										<p style={{ color: 'red' }}>{rePasswordError}</p>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											className='w-100 py-3'
											onClick={resetPasswordHandler}>
											Confirmer
										</Button>
									</div>
									<p>{message}</p>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPassword;
