import Api from '../config/axios';
import config from '../config/config';

export const getCommonInfosService = async (regions: any) => {
	const url = config.apiUrl + '/project/Dash/Kapi';
	console.log(regions);
	try {
		return await Api.post(url, {regions});
	} catch (error) {
		throw new Error('An error occured');
	}
};

export default { getCommonInfosService };
