import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DashboardService from '../services/DashboardService';

export const getCommonInfos = createAsyncThunk(
	'dashboard/get-common-infos',
	async (regions: any, thunkAPI) => {
		try {
			const { status, data } = await DashboardService.getCommonInfosService(regions);
			if (status === 201 || status === 200) {
				console.log(data);
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

const initialState = {
	getCommonInfos: {
		status: 'idle',
		data: null,
		error: {},
	},
};
const DashboardSlice = createSlice({
	name: 'dashborad',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[getCommonInfos.pending.type]: (state) => {
			state.getCommonInfos = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getCommonInfos.fulfilled.type]: (state, action) => {
			state.getCommonInfos = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getCommonInfos.rejected.type]: (state, action) => {
			state.getCommonInfos = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
	},
});

export default DashboardSlice.reducer;
