import React, { FC, useCallback, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import { signinUser } from '../../../reducers/signin';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Icon from '../../../components/icon/Icon';
import jwt_decode from 'jwt-decode';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const login = async (dispatch: any, data: any) => {
	return dispatch(signinUser(data)).unwrap();
};
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [show, setShow] = useState(false);
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const clientId = '1059456331373-mq9e6dsm7rg95gnqqk9ic288d0emt7jl.apps.googleusercontent.com';
	// const test = useSelector((state: any) => state);
	const status = useSelector((state: any) => state.Signin.signin.status);
	// const error = useSelector((state: any) => state.Signin.signin.error);
	const dispatch = useDispatch();
	const [hidePassword, setHidePassword] = useState(true);
	var [token, settoken] = useState<any>();
	 token = localStorage.getItem('token');

	useEffect(() => {
		if(token == null){
			navigate("/auth-pages/login")

		}
		if(token){
			const result1: any = jwt_decode(token);
			if (status === 'success' && token && (result1.role == "SuperAdmin") || (result1.role == "Agent")) {
				navigate('/');
			} else if (status === 'failed') {
				setMessage('Verifier votre email et mot de passe');
			}
		}
		

		console.log(status);
	}, [status, navigate ,token]);
	const handleEmailChange = (event: any) => {
		setEmail(event.target.value);
	};
	const handlePasswordChange = (event: any) => {
		setPassword(event.target.value);
	};
	const checkIsValidEmail = (val: any) => {
		/** mail regex */
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		return val ? regex.test(val) : false;
	};
	const handleSubmit = async (event: any) => {
		event.preventDefault();

		let invalidForm = false;
		const emptyFields = email === '' && password === '';

		if (emptyFields) {
			setMessage('Veuillez remplir tous les champs');
			console.log(message, 'here');
			invalidForm = true;
			return invalidForm;
		} else {
			setMessage('');
		}

		if (!email) {
			invalidForm = true;
			setEmailError("L'email est obligatoire");
		} else {
			invalidForm = false;
			setEmailError('');
		}
		if (!checkIsValidEmail(email)) {
			invalidForm = true;
			setEmailError("L'email n'est pas valide");
		} else {
			invalidForm = false;
			setEmailError('');
		}
		if (!password) {
			invalidForm = true;
			setPasswordError('Le mot de passe est obligatoire');
		} else {
			setPasswordError('');
		}
		if (invalidForm) {
			return;
		}
		let userData = {
			email: email,
			password: password,
		};
		await login(dispatch, userData);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-info': !singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										<img
											style={{ width: '200px' }}
											src='/logo5.png'
											alt='img'
										/>
									</Link>
								</div>
								{/* <div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										
									</div>
								</div> */}

								<>
									<div className='text-center h1 fw-bold mt-5 mb-4'>
										{' '}
										Connectez-vous !
									</div>
									{/* <div className='text-center h4 text-muted mb-5'>
										Connectez-vous !
									</div> */}
								</>
								<form className='row g-4'>
									<>
										<div className='col-12'>
											<div className='input-group mb-3'>
												<input
													className='form-control'
													placeholder='Adresse Email'
													type='email'
													value={email}
													onChange={handleEmailChange}
												/>
											</div>
											<p style={{ color: 'red' }}>{emailError}</p>
										</div>

										<div className='col-12'>
											<div className='input-group mb-3'>
												<input
													value={password}
													type={hidePassword ? 'password' : 'text'}
													autoComplete='password'
													onChange={handlePasswordChange}
													className='form-control'
													placeholder='Mot de Passe'
												/>
												<span
													className='input-group-text'
													id='basic-addon2'
													onClick={() =>
														setHidePassword((current) => !current)
													}>
													<Icon
														icon={hidePassword ? 'EyeSlash' : 'Eye'}
														size='md'
														color='primary'
													/>
												</span>
											</div>
											<p style={{ color: 'red' }}>{passwordError}</p>
											<p style={{ color: 'red' }}>{message}</p>
										</div>
										<div className='col-12'><a href={"/forgot-password"} target="_blank">Mot de passe oublié ?</a></div>
										<div className='col-12'>
											<Button
												color='info'
												className='w-100 py-3'
												onClick={handleSubmit}>
												Se connecter
											</Button>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
