import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AllAdminService from "../services/AllAdminService";

export const allAdmin = createAsyncThunk(
  "user/allAdmin",
  async (thunkAPI) => {
    try {
      const { status, data } = await AllAdminService.GetAllAdmin();
      if (status === 200) {
        return data;
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);
export const updatestatus = createAsyncThunk(
  "user/update",
  async (updateData:any,thunkAPI) => {
    try {
      const { status, data } = await AllAdminService.updateAdminStatus(updateData);
      if (status === 200) {
        return data;
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);
export const Addadmin = createAsyncThunk(
  "user/add",
  async (DataAdmin:any,thunkAPI) => {
    try {
      const { status, data } = await AllAdminService.addAdmin(DataAdmin);
      if (status === 200 || status=== 201) {
        return data;
      } else {
				return thunkAPI.rejectWithValue(data);
			}
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
    Admin: {
    status: "idle",
    data:null,
    error: {},
  },
  updatestatus: {
    status: "idle",
    data:null,
    error: {},
  },
  ajouteradmin: {
    status: "idle",
    data:null,
    error: {},
  },
};
const SignInSlice = createSlice({
  name: "signin-user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [allAdmin.pending.type]: (state) => {
      state.Admin = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [allAdmin.fulfilled.type]: (state, action) => {
      state.Admin = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [allAdmin.rejected.type]: (state, action) => {
      state.Admin = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
    [updatestatus.pending.type]: (state) => {
      state.updatestatus = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updatestatus.fulfilled.type]: (state, action) => {
      state.updatestatus = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [updatestatus.rejected.type]: (state, action) => {
      state.updatestatus = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
    [Addadmin.pending.type]: (state) => {
      state.ajouteradmin = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [Addadmin.fulfilled.type]: (state, action) => {
      state.ajouteradmin = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [Addadmin.rejected.type]: (state, action) => {
      state.ajouteradmin = {
        status: "failed",
        data: action.payload,

        error:action.payload,

      };
    },
  },
});

export default SignInSlice.reducer;
