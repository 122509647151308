import Api from '../config/axios';
import config from '../config/config';

export const GetAll10Projects = async (RegionData: {}) => {
	const url = config.apiUrl + '/project/Get/10Project/';

	try {
		return await Api.post(url, RegionData);
	} catch (error) {
		console.log('error GetAll10Projects: ', error);
		throw new Error('An error occured');
	}
};
export const GetFile = async (Name: any) => {
	const url = config.apiUrl + '/project/getFile/' + Name;

	try {
		return await Api.get(url);
	} catch (error) {
		console.log('error GetAll10Projects: ', error);
		throw new Error('An error occured');
	}
};
export const FiltrerparStatus = async (Data: {}) => {
	const url = config.apiUrl + '/project/Filtrer/parstatus/';

	try {
		return await Api.post(url, Data);
	} catch (error) {
		console.log('error GetAll10Projects: ', error);
		throw new Error('An error occured');
	}
};
export const PDFparStatus = async (Data: {}) => {
	const url = config.apiUrl + '/project/createPDF/Status';

	try {
		return await Api.post(url, Data);
	} catch (error) {
		console.log('error GetAll10Projects: ', error);
		throw new Error('An error occured');
	}
};
export default { GetAll10Projects, FiltrerparStatus, PDFparStatus, GetFile };
