import { configureStore } from '@reduxjs/toolkit';
import dashboard from '../reducers/dashboard';
import donation from '../reducers/donation';
import project from '../reducers/project';
import allProject from '../reducers/allProject';
import allCamapign from '../reducers/allCamapign';
import allDonation from '../reducers/allDonation';
import projectCategory from '../reducers/projectCategory';
import allAdmin from '../reducers/allAdmin';
import campaign from '../reducers/campaign';
import campaignquadratic from '../reducers/allCampaignQuadratic';

import signin from "../reducers/signin";
import pdf from "../reducers/pdf";
import entreprise from '../reducers/entreprise';

export default configureStore({
	reducer: {
		Project: project,
		Campaign: campaign,
		CampaignQuadratic:campaignquadratic,
		Pdf: pdf,
		AllProject:allProject,
		AllCamapign:allCamapign,
		AllDonation:allDonation,
		Signin: signin,

		Dashborad: dashboard,
		ProjectCategory: projectCategory,
		Donation: donation,
		AllAdmin:allAdmin,
		Entreprise: entreprise
	},
});
