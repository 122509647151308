import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AllCampaignService from "../services/AllCampaignQuadratic";

export const allCamapignQuadratic = createAsyncThunk(
  "Campaign-quadratic/all",
  async (statusQ: any, thunkAPI) => {
    try {
      const { status, data } = await AllCampaignService.getAllCampaignsQuadratic(statusQ);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const detailsCamapignQuadratic = createAsyncThunk(
  "Campaign-quadratic/Details",
  async (statusQ: any, thunkAPI) => {
    try {
      const { status, data } = await AllCampaignService.getDetailsCampaignsQuadratic(statusQ);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const statusCamapignQuadratic = createAsyncThunk(
  "Campaign-quadratic/status",
  async (statusQ: any, thunkAPI) => {
    try {
      const { status, data } = await AllCampaignService.StatusCampaignsQuadratic(statusQ);
      if (status === 200) {
        return data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialState = {
    CampaignQuadratic: {
    status: "idle",
    data:null,
    error: {},
  },
  DetailsQuadratic: {
    status: "idle",
    data:null,
    error: {},
  },
  StatusQuadratic: {
    status: "idle",
    data:null,
    error: {},
  },
};
const SignInSlice = createSlice({
  name: "campaign-quadratic",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [allCamapignQuadratic.pending.type]: (state) => {
      state.CampaignQuadratic = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [allCamapignQuadratic.fulfilled.type]: (state, action) => {
      state.CampaignQuadratic = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [allCamapignQuadratic.rejected.type]: (state, action) => {
      state.CampaignQuadratic = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
    [detailsCamapignQuadratic.pending.type]: (state) => {
      state.DetailsQuadratic = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [detailsCamapignQuadratic.fulfilled.type]: (state, action) => {
      state.DetailsQuadratic = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [detailsCamapignQuadratic.rejected.type]: (state, action) => {
      state.DetailsQuadratic = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
    [statusCamapignQuadratic.pending.type]: (state) => {
      state.StatusQuadratic = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [statusCamapignQuadratic.fulfilled.type]: (state, action) => {
      state.StatusQuadratic = {
        status: "success",
        data: action.payload,

        
        error: {},
      };
    },
    [statusCamapignQuadratic.rejected.type]: (state, action) => {
      state.StatusQuadratic = {
        status: "failed",
        data: action.payload,

        error:action.payload.error,

      };
    },
  },
});

export default SignInSlice.reducer;
