import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DonationService from '../services/DonationService';

export const getDonationsByDate = createAsyncThunk(
	'donation/get-donations-by-date',
	async (donationData: any, thunkAPI) => {
		try {
			const { status, data } = await DonationService.getDonationsByDate(donationData);
			if (status === 201 || status === 200) {
				console.log(data);
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

const initialState = {
	donationsByDate: {
		status: 'idle',
		data: null,
		error: {},
	},
};
const DonationSlice = createSlice({
	name: 'dashborad',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[getDonationsByDate.pending.type]: (state) => {
			state.donationsByDate = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getDonationsByDate.fulfilled.type]: (state, action) => {
			state.donationsByDate = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getDonationsByDate.rejected.type]: (state, action) => {
			state.donationsByDate = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
	},
});

export default DonationSlice.reducer;
