import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProjectService from '../services/ProjectService';

export const getAllProjects = createAsyncThunk(
	'project/get-All-projects',
	async (filterData: any, thunkAPI) => {
		try {
			const { status, data } = await ProjectService.getAllProjects(filterData);
			if (status === 201 || status === 200) {
				console.log(data);
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

export const getNbProjectsAndDonationsByCategory = createAsyncThunk(
	'project/get-nb-projects-donations-by-category',
	async (regions: any, thunkAPI) => {
		try {
			const { status, data } = await ProjectService.getNbProjectsAndDonationsByCategory(
				regions,
			);
			if (status === 201 || status === 200) {
				console.log(data);
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

export const getNbProjectsByDate = createAsyncThunk(
	'project/get-nb-projects-by-date',
	async (dataProject: any, thunkAPI) => {
		try {
			const { status, data } = await ProjectService.getNbProjectsByDate(dataProject);
			if (status === 201 || status === 200) {
				console.log(data);
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

export const validateProject = createAsyncThunk(
	'project/validate',
	async (dataProject: any, thunkAPI) => {
		try {
			const { status, data } = await ProjectService.validateProject(dataProject);
			if (status === 201 || status === 200) {
				console.log(data);
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

export const getProjectDetails = createAsyncThunk(
	'project/get-project-details',
	async (id: any, thunkAPI: any) => {
		try {
			const { status, data } = await ProjectService.getProjectDetails(id);
			if (status === 201 || status === 200) {
				return data.value;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

const initialState = {
	getProjects: {
		status: 'idle',
		data: null,
		error: {},
	},
	getNbProjectsAndDonationsByCategory: {
		status: 'idle',
		data: null,
		error: {},
	},
	getNbProjectsByDate: {
		status: 'idle',
		data: null,
		error: {},
	},
	project: {
		status: 'idle',
		data: null,
		error: {},
	},
	projectValidate: {
		status: 'idle',
		data: null,
		error: {}
	}
};
const ProjectSlice = createSlice({
	name: 'get-projects-by-user',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[getAllProjects.pending.type]: (state) => {
			state.getProjects = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getAllProjects.fulfilled.type]: (state, action) => {
			state.getProjects = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getAllProjects.rejected.type]: (state, action) => {
			state.getProjects = {
				status: 'failed',
				data: action.payload,
				error: action.payload.error,
			};
		},
		[getNbProjectsAndDonationsByCategory.pending.type]: (state) => {
			state.getNbProjectsAndDonationsByCategory = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getNbProjectsAndDonationsByCategory.fulfilled.type]: (state, action) => {
			state.getNbProjectsAndDonationsByCategory = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getNbProjectsAndDonationsByCategory.rejected.type]: (state, action) => {
			state.getNbProjectsAndDonationsByCategory = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
		[getNbProjectsByDate.pending.type]: (state) => {
			state.getNbProjectsByDate = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getNbProjectsByDate.fulfilled.type]: (state, action) => {
			state.getNbProjectsByDate = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getNbProjectsByDate.rejected.type]: (state, action) => {
			state.getNbProjectsByDate = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
		[getProjectDetails.pending.type]: (state) => {
			state.project = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[getProjectDetails.fulfilled.type]: (state, action) => {
			state.project = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[getProjectDetails.rejected.type]: (state, action) => {
			state.project = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
		[validateProject.pending.type]: (state) => {
			state.projectValidate = {
				status: 'loading',
				data: null,
				error: {},
			};
		},
		[validateProject.fulfilled.type]: (state, action) => {
			state.projectValidate = {
				status: 'success',
				data: action.payload,
				error: {},
			};
		},
		[validateProject.rejected.type]: (state, action) => {
			state.projectValidate = {
				status: 'failed',
				data: action.payload,
				error: {},
			};
		},
	},
});

export default ProjectSlice.reducer;
