import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CampaignService from "../services/CampaignService";

export const getCampaignDetails = createAsyncThunk(
  "campaign/get-campaign-details",
  async (id: any, thunkAPI: any) => {
    try {
      const { status, data } =
        await CampaignService.getCampaignDetails(id);
      if (status === 201 || status === 200) {
        return data.value;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateCampaignStatus = createAsyncThunk(
  "campaign/update-status",
  async (dataCampaign: any, thunkAPI: any) => {
    try {
      const { status, data } =
        await CampaignService.updateCampaignStatus(dataCampaign);
      if (status === 201 || status === 200) {
        return data.value;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  campaign: {
    status: "idle",
    data: null,
    error: {},
  },
  updateStatus: {
    status: "idle",
    data: null,
    error: {},
  },
};
const CampaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getCampaignDetails.pending.type]: (state) => {
      state.campaign = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [getCampaignDetails.fulfilled.type]: (state, action) => {
      state.campaign = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [getCampaignDetails.rejected.type]: (state, action) => {
      state.campaign = {
        status: "failed",
        data: action.payload,
        error: "",
      };
    },
    [updateCampaignStatus.pending.type]: (state) => {
      state.updateStatus = {
        status: "loading",
        data: null,
        error: {},
      };
    },
    [updateCampaignStatus.fulfilled.type]: (state, action) => {
      state.updateStatus = {
        status: "success",
        data: action.payload,
        error: {},
      };
    },
    [updateCampaignStatus.rejected.type]: (state, action) => {
      state.updateStatus = {
        status: "failed",
        data: action.payload,
        error: "",
      };
    },
  },
});

export default CampaignSlice.reducer;
