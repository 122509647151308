import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SignInService from '../services/SignInService';

export const signinUser = createAsyncThunk('user/signin', async (userData: any, thunkAPI) => {
	try {
		const { status, data } = await SignInService.signinService(userData);
		if (status === 200) {
			localStorage.setItem('token', data.token);

			return data;
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	} catch (e: any) {
		console.log('Error', e.response.data);
		return thunkAPI.rejectWithValue(e.response.data);
	}
});
export const sendEmailForResetPassword = createAsyncThunk(
	'user/send-email-for-rest-password',
	async (userData: any, thunkAPI) => {
		try {
			const { status, data } = await SignInService.sendEmailForRestPasswordService(userData);
			if (status === 200 || status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);
export const resetPassword = createAsyncThunk(
	'user/rest-password',
	async (userData: any, thunkAPI) => {
		try {
			const { status, data } = await SignInService.restPasswordService(userData);
			if (status === 200 || status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e: any) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);
const initialState = {
	signin: {
		status: 'idle',
		data: {
			email: '',
			name: '',
		},
		error: {},
	},
	sendEmailForResetPassword: {
		status: 'idle',
		data: {},
		error: {},
	},
	resetPassword: {
		status: 'idle',
		data: {},
		error: {},
	},
};
const SignInSlice = createSlice({
	name: 'signin-user',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[signinUser.pending.type]: (state) => {
			state.signin = {
				status: 'loading',
				data: {
					email: '',
					name: '',
				},
				error: {},
			};
		},
		[signinUser.fulfilled.type]: (state, action) => {
			state.signin = {
				status: 'success',
				data: {
					name: action.payload.name,
					email: action.payload.email,
				},

				error: {},
			};
		},
		[signinUser.rejected.type]: (state, action) => {
			state.signin = {
				status: 'failed',
				data: {
					email: '',
					name: '',
				},
				error: {},
			};
		},
		[sendEmailForResetPassword.pending.type]: (state) => {
			state.sendEmailForResetPassword = {
				status: 'loading',
				data: {},
				error: {},
			};
		},
		[sendEmailForResetPassword.fulfilled.type]: (state, action) => {
			state.sendEmailForResetPassword = {
				status: 'success',
				data: action.payload,

				error: {},
			};
		},
		[sendEmailForResetPassword.rejected.type]: (state, action) => {
			state.sendEmailForResetPassword = {
				status: 'failed',
				data: {},
				error: action.payload,
			};
		},
		[resetPassword.pending.type]: (state) => {
			state.resetPassword = {
				status: 'loading',
				data: {},
				error: {},
			};
		},
		[resetPassword.fulfilled.type]: (state, action) => {
			state.resetPassword = {
				status: 'success',
				data: action.payload,

				error: {},
			};
		},
		[resetPassword.rejected.type]: (state, action) => {
			state.resetPassword = {
				status: 'failed',
				data: {},
				error: action.payload ? action.payload.message : '',
			};
		},
	},
});

export default SignInSlice.reducer;
