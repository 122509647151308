import Api from '../config/axios';
import config from '../config/config';

export const getAllEntreprises = async (data: any) => {
	const url = config.apiUrl + '/agentinvestor/all-by-status';
	try {
		return await Api.post(url, data);
	} catch (error) {
		throw new Error('An error occured');
	}
};

export const getEntrepriseById = async (id: string) => {
	const url = config.apiUrl + '/agentinvestor/' + id;
	try {
		return await Api.get(url);
	} catch (error) {
		throw new Error('An error occured');
	}
};


export const updateEntrepriseStatus = async (data: any) => {
	console.log('datasetrvice ',data)
    const url = `${config.apiUrl}/agentinvestor/update/AgentInvestorsStatus/${data.id}`;
    try {
        return await Api.put(url, { data});
    } catch (error) {
        throw new Error('An error occurred');
    }
};


export default { getAllEntreprises, getEntrepriseById, updateEntrepriseStatus };
